import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  userData: store.state.user.userData,
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${URL_API}employee`, { headers })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchEmployee(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios;
        axios({
          method: "get",
          url: `${URL_API}employee/${id}`,
          headers: getHeader(userData),
        })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/employee/employees", { employee: employeeData })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
