<template>
  <div>
    <b-tabs pill>
      <b-tab title="Geral" active>
        <br />
        <!-- Media -->
        <b-media class="mb-2"> </b-media>

        <b-modal
          id="modal-avatar"
          ok-title="Selecionar"
          @ok="updateAvatar"
          cancel-title="Cancelar"
          centered
          title="Atualizar Imagem"
        >
          <div>
            <croppa id="croppa" v-if="visibleComponent === 'croppa'"></croppa>
          </div>
        </b-modal>

        <!-- Employee Info: Input Fields -->
        <b-form>
          <b-row>
            <b-col class="mb-1" cols="12" md="6">
              <b-form-group label-for="Name">
                <template v-slot:label>
                  {{ $t("message.employees.fullname") }}
                </template>
                <b-form-input
                  id="employee-name"
                  v-model="employeeData.fullName"
                  placeholder="João Silva"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="6">
              <b-form-group label="Email" label-for="Email">
                <b-form-input
                  id="employee-email"
                  v-model="employeeData.internalEmail"
                  placeholder="joao.silva@email.com"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="StartDate">
                <template v-slot:label>
                  {{ $t("message.employees.admissionDate") }}
                </template>
                <flat-pickr
                  id="employee-start-date"
                  v-model="employeeData.date"
                  class="form-control"
                  :config="flatConfig"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="RegisterNumber">
                <template v-slot:label>
                  {{ $t("message.employees.registrationNumber") }}
                </template>
                <b-form-input
                  id="employee-register-number"
                  v-model="employeeData.registerNumber"
                  placeholder="0000000"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="ESocial">
                <template v-slot:label>
                  {{ $t("message.employees.e-SocialEnrollment") }}
                </template>

                <b-form-input
                  id="employee-e-social"
                  v-mask="'#.#. ###################'"
                  v-model="employeeData.socialNumber"
                  placeholder="0000000"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="12">
              <br />
              <h4 class="mb-0 ml-50">
                {{ $t("message.employees.personalData") }}
              </h4>
              <br /><br />
            </b-col>

            <b-col class="mb-1" cols="12" md="5">
              <b-form-group label-for="MotherName">
                <template v-slot:label>
                  {{ $t("message.employees.mothersName") }}
                </template>
                <b-form-input
                  id="employee-mother-name"
                  v-model="employeeData.motherName"
                  placeholder="Maria Silva"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="5">
              <b-form-group label-for="FatherName">
                <template v-slot:label>
                  {{ $t("message.employees.fathersName") }}
                </template>
                <b-form-input
                  id="employee-father-name"
                  v-model="employeeData.fatherName"
                  placeholder="José Silva"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="2">
              <b-form-group label-for="MaritalStatus">
                <template v-slot:label>
                  {{ $t("message.employees.maritalStatus") }}
                </template>
                <b-form-select
                  id="employee-marital-status"
                  v-model="employeeData.maritalStatus"
                  :options="maritalStatus"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="3">
              <b-form-group label-for="BirthPlace">
                <template v-slot:label>
                  {{ $t("message.employees.birthPlace") }}
                </template>
                <b-form-input
                  id="employee-birth-place"
                  v-model="employeeData.birthPlace"
                  placeholder="Brasileiro"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="2">
              <b-form-group label-for="BirthPlaceState">
                <template v-slot:label>
                  {{ $t("message.employees.state") }}
                </template>
                <b-form-input
                  id="employee-birth-place-state"
                  v-model="employeeData.birthPlaceState"
                  placeholder="São Paulo"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="3">
              <b-form-group label-for="Nationality">
                <template v-slot:label>
                  {{ $t("message.employees.nationality") }}
                </template>
                <b-form-input
                  id="employee-nationality"
                  v-model="employeeData.nationality"
                  placeholder="Brasileiro"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="2">
              <b-form-group label-for="DateBirth">
                <template v-slot:label>
                  {{ $t("message.employees.birthDate") }}
                </template>
                <flat-pickr
                  v-model="employeeData.dateBirth"
                  id="employee-birth-date"
                  class="form-control"
                  :config="flatConfig"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="2">
              <b-form-group label-for="Sex">
                <template v-slot:label>
                  {{ $t("message.employees.gender") }}
                </template>
                <b-form-select
                  id="employee-sex"
                  v-model="employeeData.sex"
                  :options="sex"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="SexOrientation">
                <template v-slot:label>
                  {{ $t("message.employees.sexualOrientation") }}
                </template>
                <b-form-select
                  id="employee-sex-orientation"
                  v-model="employeeData.sexualOrientation"
                  :options="sexOrientation"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="PesonalEmail">
                <template v-slot:label>
                  {{ $t("message.employees.personalEmail") }}
                </template>
                <b-form-input
                  id="employee-personal-email"
                  v-model="employeeData.internalEmail"
                  placeholder="joao@email.com"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="MobileNumber">
                <template v-slot:label>
                  {{ $t("message.employees.cellPhone") }}
                </template>
                <b-form-input
                  id="employee-mobile-number"
                  v-model="employeeData.mobilePhoneNumber"
                  placeholder="+55(00)00000-0000"
                /><small style="color: red;">{{ $t("dddAndCode") }}</small>
              </b-form-group>
              
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="PhoneNumber">
                <template v-slot:label>
                  {{ $t("message.employees.phone") }}
                </template>
                <b-form-input
                  id="employee-phone-number"
                  v-mask="'(##) ####-####'"
                  v-model="employeeData.phoneNumber"
                  placeholder="(00)00000-0000"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="2">
              <b-form-group label="CEP" label-for="ZipCode">
                <b-form-input
                  id="employee-zipcode"
                  v-mask="'#####-###'"
                  v-model="employeeData.zipCode"
                  placeholder="00000-000"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="6">
              <b-form-group label="Endereço" label-for="Address">
                <template v-slot:label>
                  {{ $t("message.employees.homeAddress") }}
                </template>
                <b-form-input
                  id="employee-address"
                  v-model="employeeData.address"
                  placeholder="Rua um, 01"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="District">
                <template v-slot:label>
                  {{ $t("message.employees.district") }}
                </template>
                <b-form-input
                  id="employee-district"
                  v-model="employeeData.district"
                  placeholder="Alphaville"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="City">
                <template v-slot:label>
                  {{ $t("message.employees.city") }}
                </template>
                <b-form-input
                  id="employee-city"
                  v-model="employeeData.city"
                  placeholder="São Paulo"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label="UF" label-for="Uf">
                <b-form-input
                  id="employee-uf"
                  v-model="employeeData.uf"
                  placeholder="SP"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="12">
              <br />
              <h4 class="mb-0 ml-50">{{ $t("message.employees.bankData") }}</h4>
              <br /><br />
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="BankAccount">
                <template v-slot:label>
                  {{ $t("message.employees.account") }}
                </template>
                <b-form-input
                  id="employee-bank-account"
                  v-model="employeeData.bankAccount"
                  placeholder="00000-0"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="BankAgency">
                <template v-slot:label>
                  {{ $t("message.employees.agency") }}
                </template>
                <b-form-input
                  id="employee-bank-agency"
                  v-model="employeeData.bankAgency"
                  placeholder="0000"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="Bank">
                <template v-slot:label>
                  {{ $t("message.employees.bank") }}
                </template>
                <b-form-input id="employee-bank" v-model="employeeData.bank" />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="12">
              <br />
              <h4 class="mb-0 ml-50">
                {{ $t("message.employees.documents") }}
              </h4>
              <br /><br />
            </b-col>
            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="Ctps">
                <template v-slot:label>
                  {{ $t("message.employees.workCard") }}
                </template>
                <b-form-input
                  id="employee-ctps"
                  v-mask="'########.###-#'"
                  v-model="employeeData.ctps"
                  placeholder="0000000 000-0"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="2">
              <b-form-group label-for="CtpsDate">
                <template v-slot:label>
                  {{ $t("message.employees.shippingDate") }}
                </template>
                <flat-pickr
                  v-model="employeeData.ctpsDate"
                  id="employee-ctps-date"
                  class="form-control"
                  :config="flatConfig"
                />
              </b-form-group>
            </b-col>

            <b-col class="mb-1" cols="12" md="4">
              <b-form-group label-for="pis">
                <template v-slot:label>
                  {{ $t("message.employees.pis") }}
                </template>
                <b-form-input
                  id="employee-pis"
                  v-mask="'###.######.##-#'"
                  v-model="employeeData.pis"
                  placeholder="000.00000.00-0"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="PisDate">
                <template v-slot:label>
                  {{ $t("message.employees.pisRegistrationDate") }}
                </template>
                <flat-pickr
                  v-model="employeeData.pisDate"
                  id="employee-pis-date"
                  class="form-control"
                  :config="flatConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label-for="IdentityId">
                <template v-slot:label>
                  {{ $t("message.employees.rg") }}
                </template>
                <b-form-input
                  id="employee-identity-id"
                  v-mask="'##.###.###-#'"
                  v-model="employeeData.identityId"
                  placeholder="00.000.000-0"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group label-for="IdentityIdState">
                <template v-slot:label>
                  {{ $t("message.employees.issuingAgency") }}
                </template>
                <b-form-input
                  id="employee-identity-id-state"
                  v-model="employeeData.identityIdState"
                  placeholder="SSP"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group label="UF" label-for="IdentityIdUF">
                <b-form-input
                  id="employee-identity-id-uf"
                  v-model="employeeData.identityIdUF"
                  placeholder="SP"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="8">
              <b-form-group label-for="DriverId">
                <template v-slot:label>
                  {{ $t("message.employees.qualification") }}
                </template>
                <b-form-input
                  id="employee-driver-id"
                  v-mask="'############'"
                  v-model="employeeData.driveId"
                  placeholder="000123456789"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="DriverCategory">
                <template v-slot:label>
                  {{ $t("message.employees.category") }}
                </template>
                <v-select
                  id="employee-driver-category"
                  v-model="employeeData.driveCategory"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="driverCategory"
                  multiple
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="DriveeExpireDate">
                <template v-slot:label>
                  {{ $t("message.employees.dueDate") }}
                </template>
                <flat-pickr
                  v-model="employeeData.driveExpireDate"
                  id="employee-drive-expire-date"
                  class="form-control"
                  :config="flatConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="MilitaryId">
                <template v-slot:label>
                  {{ $t("message.employees.militaryDocument") }}
                </template>
                <b-form-input
                  id="employee-military-id"
                  v-mask="'############'"
                  v-model="employeeData.militaryId"
                  placeholder="000000000000 "
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label-for="VoterId">
                <template v-slot:label>
                  {{ $t("message.employees.voterTitle") }}
                </template>
                <b-form-input
                  id="employee-voter-id"
                  v-mask="'#### #### ##### ##'"
                  v-model="employeeData.voterIdentification"
                  placeholder="0000 0000 0000 00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group label-for="VoterZone">
                <template v-slot:label>
                  {{ $t("message.employees.zone") }}
                </template>
                <b-form-input
                  id="employee-voter-zone"
                  v-mask="'###'"
                  v-model="employeeData.voterZone"
                  placeholder="000"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group label-for="VoterSession">
                <template v-slot:label>
                  {{ $t("message.employees.section") }}
                </template>
                <b-form-input
                  id="employee-voter-session"
                  v-mask="'####'"
                  v-model="employeeData.voterSession"
                  placeholder="0000"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="CPF" label-for="Cpf">
                <b-form-input
                  id="employee-cpf"
                  v-mask="'###.###.###-##'"
                  v-model="employeeData.cpf"
                  placeholder="000.000.000-00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="foreigner" label-class="mb-1">
                <template v-slot:label>
                  {{ $t("message.employees.foreign") }}
                </template>
                <b-form-radio-group
                  id="employee-foreigner"
                  v-model="employeeData.foreign"
                  :options="yesNo"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="RNE" label-for="Rne">
                <b-form-input
                  id="employee-rne"
                  v-model="employeeData.rne"
                  placeholder="A0000000-0"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
      <b-tab title="Profissional"
        ><br />
        <b-form class="mt-1">
          <b-row>
            <b-col cols="12" md="2">
              <b-form-group label-for="AdmissionSalary">
                <template v-slot:label>
                  {{ $t("message.employees.salary") }}
                </template>
                <b-form-input
                  id="employee-admission-salary"
                  v-money="getCurrencyFormat()"
                  v-model="employeeData.admissionSalary"
                  placeholder="0000,00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="AdmissionTitle">
                <template v-slot:label>
                  {{ $t("message.employees.admissionSalary") }}
                </template>
                <b-form-input
                  id="employee-admission-title"
                  v-money="getCurrencyFormat()"
                  v-model="employeeData.admissionTitle"
                  placeholder="0000,00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="AdmissionSalary">
                <template v-slot:label>
                  {{ $t("message.employees.fixedCost") }}
                </template>
                <b-form-input
                  id="employee-admission-salary"
                  v-money="getCurrencyFormat()"
                  v-model="employeeData.fixedCost"
                  placeholder="0000,00"
                  format="currency"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group label-for="WeeklyWorkingHours">
                <template v-slot:label>
                  {{ $t("message.employees.weekHours") }}
                </template>
                <b-form-input
                  id="employee-weekly-working-hours"
                  v-mask="'##'"
                  v-model="employeeData.weeklyWorkingHours"
                  placeholder="00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group label-for="MonthlyWorkingHours">
                <template v-slot:label>
                  {{ $t("message.employees.monthlyHours") }}
                </template>
                <b-form-input
                  id="employee-monthly-working-hours"
                  v-mask="'###'"
                  v-model="employeeData.monthlyWorkingHours"
                  placeholder="000"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="PaymentType">
                <template v-slot:label>
                  {{ $t("message.employees.formOfPayment") }}
                </template>
                <b-form-input
                  id="employee-payment-type"
                  v-model="employeeData.paymentType"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group label-for="dateMedicalExamination">
                <template v-slot:label>
                  {{ $t("message.employees.dateMedicalExamination") }}
                </template>
                <flat-pickr
                  v-model="employeeData.medicalExamDate"
                  d="employee-medical-exam-date"
                  class="form-control"
                  :config="flatConfig"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="5">
              <b-form-group label-for="MedicalExamPlace">
                <template v-slot:label>
                  {{ $t("message.employees.locale") }}
                </template>
                <b-form-input
                  id="employee-medical-exam-place"
                  v-model="employeeData.medicalExamPlace"
                  placeholder="Rua 1"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group label-for="Department">
                <template v-slot:label>
                  {{ $t("message.employees.department") }}
                </template>
                <b-form-input
                  id="employee-department"
                  v-model="employeeData.department"
                  placeholder="00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group label-for="Sector">
                <template v-slot:label>
                  {{ $t("message.employees.sector") }}
                </template>
                <b-form-input
                  id="employee-sector"
                  v-mask="'#####-#'"
                  v-model="employeeData.sector"
                  placeholder="00000-0"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="1">
              <b-form-group label-for="Session">
                <template v-slot:label>
                  {{ $t("message.employees.section") }}
                </template>
                <b-form-input
                  id="employee-session"
                  v-mask="'##'"
                  v-model="employeeData.session"
                  placeholder="00"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="WorkCategory">
                <template v-slot:label>
                  {{ $t("message.employees.workerCategory") }}
                </template>
                <b-form-input
                  id="employee-work-category"
                  v-model="employeeData.workCategory"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="Role">
                <template v-slot:label>
                  {{ $t("message.employees.function") }}
                </template>
                <v-select
                  id="employee-driver-category"
                  v-model="employeeData.projectRole"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="ContractType">
                <template v-slot:label>
                  {{ $t("message.employees.typeOfContract") }}
                </template>
                <v-select
                  v-model="employeeData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="contractOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="contract"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="DeficiencyPerson">
                <template v-slot:label>
                  {{ $t("message.employees.disabledPerson") }}
                </template>
                <b-form-select
                  id="employee-deiciency-person"
                  v-model="employeeData.deficiencyPerson"
                  :options="pcd"
                  placeholder="Selecione"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="8">
              <b-form-group label-for="DeficiencyObservations">
                <template v-slot:label>
                  {{ $t("message.employees.comments") }}
                </template>
                <b-form-input
                  id="employee-deficiency-observations"
                  v-model="employeeData.deficiencyObservations"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <br />
              <h4 class="mb-0 ml-50">Social</h4>
              <br /><br />
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="Git" label-for="Git">
                <b-form-input
                  id="employee-git"
                  v-model="employeeData.git"
                  placeholder="www.github.com/"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label="LinkedIn" label-for="LinkedIn">
                <b-form-input
                  id="employee-linkedin"
                  v-model="employeeData.linkedin"
                  placeholder="www.linkedin.com/user"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
              <b-form-group label-for="professionalRegionalCouncil">
                <template v-slot:label>
                  {{ $t("message.employees.regionalProfessionalCouncil") }}
                </template>
                <b-form-input
                  id="employee-professional-regional-council"
                  v-model="employeeData.professionalRegionalCouncil"
                  placeholder="000000000"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-tab>
      <b-tab title="Demais dados"
        ><br /><br />
        <b-form>
          <table class="minimalistBlack">
            <thead>
              <tr>
                <th>{{ $t("message.employees.daysOfWeek") }}</th>
                <th>{{ $t("message.employees.workSchedule") }}</th>
                <th>{{ $t("message.employees.restandFeedingInterval") }}</th>
              </tr>
            </thead>
            <tr>
              <td>{{ $t("message.employees.monday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleMondayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input v-model="employeeData.scheduleMondayEnd" type="time" />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleMondayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleMondayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.employees.tuerday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleTuesdayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input v-model="employeeData.scheduleTuesdayEnd" type="time" />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleTuesdayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleTuesdayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.employees.wednesday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleWednesdayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleWednesdayEnd"
                  type="time"
                />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleWednesdayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleWednesdayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.employees.thursday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleThursdayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleThursdayEnd"
                  type="time"
                />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleThursdayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleThursdayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.employees.friday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleFridayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input v-model="employeeData.scheduleFridayEnd" type="time" />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleFridayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleFridayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.employees.saturday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleSaturdayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleSaturdayEnd"
                  type="time"
                />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleSaturdayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleSaturdayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t("message.employees.sunday") }}</td>
              <td>
                <input
                  v-model="employeeData.scheduleSundayStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input v-model="employeeData.scheduleSundayEnd" type="time" />
              </td>
              <td>
                <input
                  v-model="employeeData.scheduleSundayLunchStart"
                  type="time"
                /><span> {{ $t("message.employees.at") }} </span
                ><input
                  v-model="employeeData.scheduleSundayLunchEnd"
                  type="time"
                />
              </td>
            </tr>
          </table>
        </b-form>
      </b-tab> </b-tabs
    ><br />

    <div class="text-right">
      <b-button
        @click="updateEmployee()"
        variant="primary"
        :to="{ name: 'apps-employees-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BTab,
  BTabs,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import Croppa from "@core/croppa/Croppa.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useEmployeesList from "../employees-list/useEmployeesList";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    Croppa,
    flatPickr,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BFormSelect,
    BTab,
    BTabs,
    quillEditor,
  },

  directives: {
    money: VMoney,
  },

  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      visibleComponent: "croppa",

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      roleOptions: [],

      userData: store.state.user.userData,

      contractOptions: [],

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    async getOptions() {
      var typeList = [];
      await axios
        .get(`${URL_API}employee_type`, { headers: getHeader(this.userData) })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i].type;
            typeList.push(element);
            this.contractOptions.push({
              label: typeList[i],
              value: typeList[i],
            });
          }
        });
    },

    updateAvatar() {
      if (this.$root.$refs.CroppaAll.generateImage()) {
        this.employeeData.profilePicture =
          this.$root.$refs.CroppaAll.generateImage();
      }
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    updateEmployee() {
      if (this.$i18n.locale === "en") {
        this.employeeData.admissionSalary = Number(
          this.employeeData.admissionSalary.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
        this.employeeData.fixedCost = Number(
          this.employeeData.fixedCost.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      } else {
        this.employeeData.admissionSalary = this.switchDotsAndCommas(
          this.employeeData.admissionSalary
        );
        this.employeeData.fixedCost = this.switchDotsAndCommas(
          this.employeeData.fixedCost
        );

        this.employeeData.admissionSalary = Number(
          this.employeeData.admissionSalary.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
        this.employeeData.fixedCost = Number(
          this.employeeData.fixedCost.replace(/[^0-9\.-]+/g, "")
        ).toFixed(2);
      }

      this.employeeData.mobilePhoneNumber = this.employeeData.mobilePhoneNumber.replace(/\D/g, '');

      axios({
        method: "put",
        url: `${URL_API}employee/${this.employeeData.projectRole}/${this.employeeData.id}`,
        headers: getHeader(this.userData),
        data: this.employeeData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },
  },

  async created() {
    var roleList = [];
    await axios
      .get(`${URL_API}project_role`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].role;
          const role = "Indefinido";
          roleList.push(element);

          if (roleList[i] == "PROGRAMMER") {
            role = "Programador";
          }
          if (roleList[i] == "TESTER") {
            role = "Testador";
          }
          if (roleList[i] == "MANAGER") {
            role = "Gerente";
          }
          if (roleList[i] == "ANALYST") {
            role = "Analista";
          }
          if (roleList[i] == "ARCHITECT") {
            role = "Arquiteto";
          }
          if (roleList[i] == "TRAINEE") {
            role = "Estágiario";
          }
          if (roleList[i] == "UNDEFINED") {
            role = "Indefinido";
          }
          this.roleOptions.push({ label: role, value: roleList[i] });
        }
      });

    await this.getOptions();
  },

  setup(props) {
    const yesNo = [
      { text: "Sim", value: "yes" },
      { text: "Não", value: "no" },
    ];

    const sex = [
      { text: "Homem Cisgênero", value: "Homem Cisgênero" },
      { text: "Mulher Cisgênero", value: "Mulher Cisgênero" },
      { text: "Homem Transgênero", value: "Homem Transgênero" },
      { text: "Mulher Transgênero", value: "Mulher Transgênero" },
      { text: "Não-Binário", value: "Não-Binário" },
      { text: "Outro", value: "Outro" },
    ];

    const maritalStatus = [
      { text: "Solteiro", value: "Solteiro" },
      { text: "Casado", value: "Casado" },
      { text: "Divorciado", value: "Divorciado" },
      { text: "Viúvo", value: "Viúvo" },
    ];

    const sexOrientation = [
      { text: "Heterossexual", value: "Heterossexual" },
      { text: "Homossexual", value: "Homossexual" },
      { text: "Bissexual", value: "Bissexual" },
      { text: "Assexual", value: "Assexual" },
      { text: "Pansexual", value: "Pansexual" },
      { text: "Outro", value: "Outro" },
    ];

    const driverCategory = ["A", "B", "C", "D", "E"];

    const pcd = [
      { value: "Fis", text: "Física" },
      { value: "Aud", text: "Auditiva" },
      { value: "Vis", text: "Visual" },
      { value: "Mot", text: "Motora" },
      { value: "Men", text: "Mental" },
      { value: "Não há", text: "Não há" },
    ];

    const startTime = "08:00";
    const endTime = "17:00";
    const lunchTime = "12:00";
    const endLunchTime = "13:00";

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        props.employeeData.avatar = base64;
      }
    );

    return {
      avatarText,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,

      // Select
      yesNo,
      sex,
      maritalStatus,
      sexOrientation,
      driverCategory,
      pcd,

      // Table
      startTime,
      endTime,
      lunchTime,
      endLunchTime,
    };
  },
};
</script>

<style>
#form {
  padding-right: 0px;
  padding-left: 0px;
}
b-col {
  margin-bottom: 1rem;
}
.b-col {
  margin-bottom: 1rem;
}

table.minimalistBlack {
  border: 2px solid #eef2f7;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table.minimalistBlack td,
table.minimalistBlack th {
  border: 1px solid #c1c1c1;
}

table.minimalistBlack tbody td {
  font-size: 13px;
  color: #6c757d;
}

table.minimalistBlack thead {
  border-bottom: 1px solid #000000;
}

table.minimalistBlack thead th {
  font-size: 20px;
  font-weight: bold;
  color: #6c757d;
  text-align: center;
}

table.minimalistBlack tfoot td {
  font-size: 14px;
}

table.minimalistBlack input {
  border: 1px solid #dddddd;
  text-align: center;
  box-sizing: border-box;
  width: 40%;
  height: 30px;
}

table.minimalistBlack span {
  width: 10%;
}
</style>
