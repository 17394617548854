<template>
  <div>
    <croppa
      v-model="myCroppa"
      :width="350"
      :height="350"
      :placeholder="'Selecione uma imagem'"
      :zoom-speed="10"
      canvas-color="transparent"
    />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import Croppa from "vue-croppa";
import "vue-croppa/dist/vue-croppa.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BButton } from "bootstrap-vue";
Vue.use(Croppa);
export default {
  components: {
    BButton,
  },
  created() {
    this.$root.$refs.CroppaAll = this;
  },
  data() {
    return {
      myCroppa: null,
      imgUrl: "",
      currentAvatar: "",
    };
  },
  methods: {
    generateImage() {
      let url = this.myCroppa.generateDataUrl();
      if (!url) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Ops!`,
            icon: "AlertCircleIcon",
            variant: "danger",
            text: `Nenhuma imagem foi selecionada!`,
          },
        });
        return;
      }
      this.imgUrl = url;
      return this.imgUrl;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
