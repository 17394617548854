<template>
  <component :is="employeeData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="employeeData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Funcionário não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-employees-list' }">
          Lista de funcionários
        </b-link>
        for other employees.
      </div>
    </b-alert>

    <employees-edit-form
      v-if="employeeData"
      pills
      :employee-data="employeeData"
    />
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import store from "@/store";
import employeeStoreModule from "../employeeStoreModule";
import EmployeesEditForm from "./EmployeesEditForm.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    EmployeesEditForm,
  },
  setup() {
    const employeeData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-employee";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    axios({
      method: "get",
      url: `${URL_API}employee-all/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        employeeData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          employeeData.value = undefined;
        }
      });

    return {
      employeeData,
    };
  },
};
</script>

<style></style>
